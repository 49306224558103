import * as React from 'react';
import Layout from '../layout';
import { LandingWrapper, Catchline, Sep, LandingMessage, LandingTitle, LandingContent, LandingFormContainer, LandingForm, InputField, InputGroup, ButtonGroup, InputInfoIcon, InputInfo } from './styled';
import { Button } from '../../widgets/globaleStyledWidget';
import { colors } from '../../variables';

import {FormattedMessage, useIntl} from 'react-intl';
import { useHistory } from 'react-router-dom';
import livstickApp from '../../models/livstick';
import { ErrorInfo } from '../message/styled';



const LandingPage:React.FunctionComponent = () => {
    const intl = useIntl();
    const history = useHistory();
    /* Info Input Number */
    const [InfoNumberActive, setInfoNumberActive] = React.useState<boolean>(false);
    const openNumberInfo = () => { setInfoNumberActive(true); }
    const closeNumberInfo = () => { setInfoNumberActive(false); }

    /* Info Input */
    const [InfoActive, setInfoActive] = React.useState<boolean>(false);
    const [error, setError] = React.useState(false);
    const openInfo = () => { setInfoActive(true); }
    const closeInfo = () => { setInfoActive(false); }

    const  goToCode = async () => {
      const _code = await livstickApp.triage();
      if (_code.status == "504" ) return setError(true);
      if (_code.type) return history.push('/'+livstickApp.code)
      else return history.push('/record')

    }

    return <Layout>
      
      <LandingWrapper>
        <LandingContent>
          {/* ROMAIN : Ajout spécifique HO */}<Catchline><FormattedMessage id="global_txt_title"/></Catchline>
          {/* ROMAIN : Ajout spécifique HO */}<Sep/>
          <LandingMessage><FormattedMessage id="lp_txt_title2" /></LandingMessage>
          <LandingFormContainer>
            <LandingForm>
              <InputGroup>
                <InputField placeholder={intl.formatMessage({id: "lp_txt_code"})} onChange={(e) => livstickApp.code = e.currentTarget.value}></InputField> {/* variable : lp_txt_code */}
                <InputInfoIcon onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive == true}></InputInfoIcon>  
                <InputInfo active={InfoActive == true}><FormattedMessage id="lp_txt_info"/></InputInfo>
              </InputGroup>
              { error && <ErrorInfo><FormattedMessage id="lp_txt_invalid_code" /></ErrorInfo>}
              <ButtonGroup>
                <Button color={colors.main} hover="main" onClick={goToCode}><FormattedMessage id="lp_txt_submit"/></Button>
              </ButtonGroup>
            </LandingForm>
            </LandingFormContainer>
        </LandingContent>
      </LandingWrapper>

    </Layout>;
}

export default LandingPage;