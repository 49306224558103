import * as React from 'react';
import Layout from '../layout';
import { ConfirmWrapper, ConfirmMessage, ConfirmTitle, ConfirmContent, Sep, ResumeVideo, ShareTitle, ShareList, ShareListItem } from './styled';
import { colors } from '../../variables';
import { Button, MobileMask } from '../../widgets/globaleStyledWidget';
import icoFacebook from './images/ico-facebook.svg';
import icoInsta from './images/ico-instagram.svg';
import icoTwitter from './images/ico-twitter.svg';

import {FormattedMessage} from 'react-intl';
import livstickApp from '../../models/livstick';

import videojs from 'video.js';
import manifest from '../../manifest';

const videoJsOptions = {
  controls: true,
  bigPlayButton: true,
  fluid: true,
};


const EndPage:React.FunctionComponent = () => {

  const videoEl = React.useRef(null);

  const start = async () => {
    const _res = await livstickApp.pullNoLog();
    

    console.log('ref', videoEl)
    var _player = videojs(videoEl.current, videoJsOptions, () => {
      const version_info = 'Using video.js ' + videojs.VERSION;
      videojs.log(version_info);
      _player.src({ src: _res.media_url });
      _player.load();
      //_player.play();
    });

  }

  React.useEffect(() => {
    start();
  }, [])
    return <Layout>
      <ConfirmWrapper>
        <ConfirmContent>
          <ResumeVideo>
            <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
          </ResumeVideo>
          {manifest.VM && <p>https://mm2.fr/liv4/vm.html#/{livstickApp.code}</p>}
          <ConfirmTitle><FormattedMessage id="form_txt_success_title"/></ConfirmTitle>
          {/* {livstickApp.planification && <>Votre message sera envoyé le {moment()}</>} */}
          {/* <FormattedMessage id="form_txt_sent" defaultMessage="Votre message sera envoyé le ..."/> */}
          <ConfirmMessage><FormattedMessage id="form_txt_success"/></ConfirmMessage>
          {/*<Button color={colors.main} hover="main"><FormattedMessage id="form_txt_button_edit"/></Button>*/}
          {/* ROMAIN : Ajout pour HO */}
          <Sep/>
          
          <ShareList>
            <ShareListItem color={colors.facebook}><a href="https://www.facebook.com/HistoiredOr/" target="_blank"><img src={icoFacebook} /></a></ShareListItem>
            <ShareListItem color={colors.twitter}><a href="https://www.pinterest.fr/histoiredor/_shop/
" target="_blank"><img src={icoTwitter} /></a></ShareListItem>
            <ShareListItem color={colors.instagram}><a href="https://www.instagram.com/histoiredor/" target="_blank"><img src={icoInsta} /></a></ShareListItem>
          </ShareList>
          
        </ConfirmContent>
      </ConfirmWrapper>

    </Layout>;
}

export default EndPage;