import styled, { css } from "styled-components";
import { colors } from './../../variables';

export const ConfirmWrapper = styled.div`
  width: 300px;
  border-radius: 0px;
  position: relative;
  margin-top: 40px;
  z-index: 30;
  left: 10%;
  @media (max-width: 991px){
    position: relative;
    background-color: transparent;
    border: 0px;
    margin: 0 auto;
    left: inherit;
    width: 100%;
    margin-top: 90px;
  }
  @media (max-width: 767px){
    margin-top: 20px;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #000;
  @media (max-width: 991px){
    background-color: #000;
  }
`;

export const OnMobile = styled.div`
  
  display: none;
  @media (max-width: 767px){
    display: block;
  }
  
`;

export const OnDesktop = styled.div`
  display: block;
  @media (max-width: 767px){
    display: none;
  }
`;

export const ConfirmContent = styled.div`
  padding: 0px;
  color: #000;
  text-align: center;
  background-color: #fff;
  @media (max-width: 991px){
    background-color: transparent;
  }
  button{
    display: inline-block;
    @media (max-width: 991px){
      padding: 0px 50px;
    }
  }
`;

export const ConfirmTitle = styled.div`
  margin-top: 20px;
  font-size: 28px;
  color: #000;
  text-align: center;
  font-family: 'Unna';
  @media (max-width: 991px){
    color: #000;
    margin-bottom: 10px;
    margin-top: 0px;
  }
`;

export const ConfirmMessage = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
  color: #000;
  @media (max-width: 991px){
    color: #000;
  }
`;

export const ResumeVideo = styled.div`
  background-color: #000;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  margin: 0 auto;
  margin-top: 0px;
  @media (max-width: 767px){
    width: 100%;
    height: auto;
  }
`;

export const ShareTitle = styled.div`
  font-size: 12px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
`;

export const ShareList = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
`;

export const ShareListItem = styled.li<{color: string}>`
  list-style: none;
  display: inline-block;
  margin: 5px 2px;
  color: #000;
  margin-bottom: 20px;
  
  a{
    background-color: ${props => props.color};
    background-color: #CAB671;
    color: #000;
    display: flex;
    font-size: 11px;
    padding: 5px 5px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 0px;
  }
  img{
    height: 20px;
    width: auto;
    margin-right: 0px;
  }
`;