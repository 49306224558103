import styled from "styled-components";
import { colors } from './../../variables';

export const Message = styled.div`
 
`;


export const Safari = styled.div`
  text-align: center!important;
  color: #000;
  font-size: 15px;
  padding: 0px 20px;
  border-radius: 0px;
  margin-top: 150px;
  z-index: 30;
  
`;

export const Title = styled.h3`
text-align: center;
color: #000;
font-size: 32px;
margin-bottom: 20px;
margin-top: 10px;
color: #000;
  font-weight: bold;
padding: 0px 20px;
`;

export const Text = styled.p`
text-align: center;
color: #fff;
font-size: 15px;
margin-bottom: 20px;
margin-top: 10px;
padding: 0px 20px;
`;

