import styled, { css } from "styled-components";
import { Button } from '../../widgets/globaleStyledWidget';
import { colors, lang } from '../../variables';
import icoRecord from './images/ico-movie.svg';

export const Safari = styled.div`
  text-align: center!important;
  color: #000;
  font-size: 15px;
  padding: 0px 20px;
  margin-top: 110px;
  .step{
    text-align: center;
  }
`;

export const Title = styled.h3`
text-align: center;
color: #000;
font-size: 32px;
margin-bottom: 20px;
margin-top: 10px;
padding: 0px 20px;
font-family: 'Mulish';
`;

export const Text = styled.p`
text-align: center;
color: #000;
font-size: 15px;
margin-bottom: 20px;
font-family: 'Mulish';
margin-top: 10px;
padding: 0px 20px;
`;

export const SubtitleMobile = styled.h3`
  text-align: center;
  color: #000;
  font-size: 15px;
  margin-bottom: 50px;
  margin-top: 10px;
  padding: 0px 20px;
  font-family: 'Mulish';
`;

export const ButtonMobile = styled.button`
  width: 175px;
  height: 175px;
  display: block;
  margin: 0 auto;
  z-index: 100;
  border-radius: 175px;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  border: 0px;
  background-color: #000;
  background-image: url(${icoRecord});
  background-size: 80px auto;
  background-position: center 30px;
  background-repeat: no-repeat;
  animation: glowing 2000ms infinite;
  padding-top: 70px;
  margin-bottom: 80px;
  z-index: 2;
  position: relative;
  &:focus{
    outline: 0px;
  }
  &:after{
    content: '';
    position: absolute;
    width: 210px;
    height: 210px;
    border-radius: 200px;
    background-color: transparent;
    top: -22px;
    left: -22px;
  }
`;


/**
 * Message : composant permettant de saisir un message
 */

export const Catchline = styled.h1`
  position: relative;
  font-size: 19px;
  font-weight: bold;
  color: #3A5179;
  text-transform: uppercase;
  font-style: normal;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    margin-top: 30px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
  }
`;

export const TitleDesktop = styled.h1`
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  span{
    font-family: 'Dancing Script';
    font-weight: normal;
    font-size: 32px;
  }
`;

export const RecordWrapper = styled.div`
  width: 50vw;
  margin-top: 100px;
  position: absolute;
  left: 5%;
  .myVideo-dimensions.vjs-fluid{
    padding-top: 65%;
  }
  .vjs-record .vjs-play-control.vjs-control{
    display: none;
  }
  .vjs-record .vjs-record-button.vjs-control{
    display: none;
  }
  .vjs-record .vjs-pip-button.vjs-control{
    display: none;
  }
  .vjs-record.video-js .vjs-control.vjs-fullscreen-control{
    display: none;
  }
  .video-js .vjs-time-control{
    font-size: 18px;
    text-shadow: 0 0 25px #000;
  }
  .video-js .vjs-control-bar{
    background-color: transparent;
    top: 10px;
    left: 10px;
    bottom: inherit;
  }
  .vjs-duration-display, .vjs-live .vjs-time-divider{
    display: none!important;
    opacity: 0;
  }
  .vjs-record.video-js .vjs-control.vjs-record-indicator:before{
    font-size: 12px;
    text-shadow: 0 0 25px #000;
  }
  .video-js .vjs-volume-panel{
    display: none!important;
  }
  .vjs-record.video-js .vjs-control.vjs-record-indicator{
    right: 24px;
    top: 26px;
  }
  .vjs-live.vjs-time-control.vjs-duration{
    display: none!important;
    opacity: 0;
  }
  @media (max-width: 991px){
    width: 100%;
    position: relative;
    left: inherit;
    margin-top: 0px;
    .video-js{
      height: 100%;
      width: auto;
    }
  }
`;

export const OnMobile = styled.div`
  
  display: none;
  @media (max-width: 991px){
    display: block;
  }
  
`;

export const OnDesktop = styled.div`
  display: block;
  @media (max-width: 991px){
    display: none;
  }
`;

export const ModuleListItem = styled.li<{active?: boolean}>`
  display: inline-block;
  border-right: 1px solid #3A5179;
  padding: 0px 8px;
  font-size: 14px;
  text-transform: uppercase;
  color: #D01641;
  margin-top: 10px;
  text-align: left;
  opacity: 1;
  a{
    color: #3A5179;
    text-decoration: inherit; 
    cursor: pointer;
  }
  ${props => props.active && css`
    font-weight: bold;
    color: #D01641;
    opacity: 1;
    a{
      color: #D01641;
    }
  `}
  @media (max-width: 767px){
    font-size: 12px;
    font-weight: bold;
  }
  &:first-child{
    padding-left: 0px;
  }
  &:last-child{
    border-right: 0px;
  }
  
  
`;
export const ModuleContainer = styled.div`
  position: relative;
  text-align: left;
`;



export const ActionsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 767px){
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const ListModules = styled.ul`
  margin: 0px;
  padding: 0px;
  z-index: 40;
  position: relative;
  
`;

export const WebcamWrapper = styled.div`
  background-color: #000;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: 100%;
  /*
  -webkit-box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.75);
  */
  @media (max-width: 767px){
    height: 500px;
    width: auto;
  }
`;

export const RecordHeader = styled.div`
  text-align: center;
  padding: 0px;
  @media (max-width: 767px){
    display: block;
    text-align: center;
  }
`;

export const StepNumber = styled.div`
  font-size: 12px;
  text-align: right;
  color: #000;
  font-weight: 600;
  z-index: 40;
  margin-bottom: 10px;
  position: relative;
  text-transform: uppercase;
  @media (max-width: 991px){
    display: block;
    text-align: center;
    margin-top: 0px;
  }
`;